<template>
    <v-app>
        <v-main>
            <router-view/>
        </v-main>
    </v-app>
</template>

<script>

export default {
    name: 'App',

    data () {
        return {
            defaultTitle: 'Manager Ecvols'
        }
    },
    mounted() {
    },
    methods: {
        setMetaLang(title = '') {
                
            if(title)
                this.setTitleAndDescription((this.titlePage ? this.titlePage : title) + ' | ' + this.defaultTitle)

            let items = []

            this.$route.matched.forEach((route, i) => {

                if(route.meta.title){
                    let item = {
                        text: route.meta.title,
                        disabled: false,
                        to: route.path ? route.path : '/',
                    }

                    if(i === this.$route.matched.length - 1) {
                        item.text = this.titlePage ? this.titlePage : route.meta.title
                        item.disabled = true
                    }

                    items.push(item)
                }
            })

            this.$store.commit('Update', {
                    key: 'breadcrumbsItems',
                    value: items
            })

            this.$store.commit('Update', {
                    key: 'titlePage',
                    value: ''
            })
        },
        setTitleAndDescription(title = '', description = '') {
            document.title = title

            if (description)
                    document.querySelector('meta[name="description"]').setAttribute("content", description)
        }

    },
    watch: {
        $route: {
            handler(to) {
                    this.setMetaLang(to.meta.title)
            }
        },
        titlePage(title) {
            if(title)
                    this.setMetaLang(title)
        }
    },
    created() {
        this.setMetaLang(this.$route.meta.title)
    },
    computed: {
        titlePage() {
            return this.$store.state.titlePage
        }
    }
}
</script>

<style lang="scss">
    @import 'sass/main.scss';
</style>