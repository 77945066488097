<template>
    <v-row>
        <v-col cols="12" md="4">
            <v-card>
                <v-card-title>
                    Клиент
                </v-card-title>
                <v-card-text v-if="client">
                    <v-list lines="one">
                        <v-list-item-title>
                            ФИО
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            {{ client.name }}
                        </v-list-item-subtitle>
                    </v-list>
                    <v-list lines="one">
                        <v-list-item-title>
                            Телефон
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            {{ client.phone }}
                        </v-list-item-subtitle>
                    </v-list>
                    <v-list lines="one">
                        <v-list-item-title>
                            E-mail
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            {{ client.email }}
                        </v-list-item-subtitle>
                    </v-list>
                </v-card-text>
            </v-card>
        </v-col>

        <v-col cols="12" md="5">
            <v-card>
                <v-card-title>
                    Активные сессии в приложении
                </v-card-title>
                <v-card-text v-if="activeSession.length">
                    <v-list-item v-for="item in activeSession" :key="item.id">
                        <v-list-item-title>
                            {{ item.agent ? item.agent : item.id }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            Последний вход: <b>{{ item.lastUsed }}</b><br />
                            Первый вход: <b>{{ item.created }}</b>
                        </v-list-item-subtitle>

                        <template v-slot:append>
                            <v-chip v-if="item.status_push" compact size="small" color="success">
                                PUSH
                            </v-chip>
                        </template>
                    </v-list-item>
                </v-card-text>
            </v-card>

        </v-col>
    </v-row>
</template>
<script>
export default {
    name: 'DashBoard',
    data: () => ({
        client: {},
        app: []
    }),
    mounted() {
        this.get()
    },
    methods: {
        get() {
            this.loading = true

            this.$store.dispatch('API', {
                method: 'GET',
                url: '/client',
            }).then(res => {

                if(res.data){
                    if(res.data.status === 'success') {
                        this.client = res.data.data.client
                        this.app = res.data.data.app
                    }
                }

                this.loading = false
            })
        }
    },
    computed: {
        activeSession() {
            let items = []

            this.app.forEach(item => {

                if(Number(item.status_device)) item.statusText = '<span class="text-success">В сети</span>'
                else item.statusText = '<span class="text-error">Оффлайн</span>'

                item.created = this.$moment(item.created_at).calendar(null, {
                    sameDay: '[Сегодня], HH:mm:ss',
                    nextDay: '[Завтра], HH:mm:ss',
                    nextWeek: 'DD.MM.YYYY HH:mm:ss',
                    lastDay: 'DD.MM.YYYY HH:mm:ss',
                    lastWeek: 'DD.MM.YYYY HH:mm:ss',
                    sameElse: 'DD.MM.YYYY HH:mm:ss'
                })

                item.lastUsed = this.$moment(item.last_used_at).calendar(null, {
                    sameDay: '[Сегодня], HH:mm:ss',
                    nextDay: '[Завтра], HH:mm:ss',
                    nextWeek: 'DD.MM.YYYY HH:mm:ss',
                    lastDay: 'DD.MM.YYYY HH:mm:ss',
                    lastWeek: 'DD.MM.YYYY HH:mm:ss',
                    sameElse: 'DD.MM.YYYY HH:mm:ss'
                })

                items.push(item)
            })

            return items
        }
    },
    created() {
        this.get()        
    },
}
</script>