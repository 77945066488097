<template>
    <h1 class="text-h4 mb-1">{{ titleHeading }}</h1>

    <div class="app-catalog__filter">
        <v-row 
            no-gutters
        >
            <v-col 
                sm="12"
                md="8"
            >
                <v-row
                    class="justify-space-between align-center"
                    no-gutters
                >
                    <v-col 
                        sm="8"
                        md="8"
                        class="pr-2"
                    >
                        <v-text-field
                            v-model="filter.search"
                            clearable
                            label="Поиск"
                            prepend-icon="fa fa-search"
                            variant="outlined"
                            density="compact"
                            hide-details
                            clear-icon="fa fa-close"
                            :loading="loadingFilter ? 'indigo-darken-3' : false"
                            @keyup="setFilter(true)"
                            @click:clear="setFilter"
                        ></v-text-field>
                    </v-col>
                    <v-col 
                        sm="4"
                        md="2"
                        class="pr-2"
                    >
                        <v-select
                            v-model="filter.searchType"
                            label="Поиск по"
                            :items="searchTypeItems"
                            item-title="name"
                            item-value="value"
                            variant="outlined"
                            hide-details
                            density="compact"
                            menu-icon="fa fa-angle-down"
                            :disabled="loadingFilter"
                        ></v-select>
                    </v-col>
                    <v-col 
                        sm="4"
                        md="2"
                    >
                        <v-select
                            v-model="filter.status"
                            clearable
                            label="Статус"
                            :items="searchStatusItems"
                            item-title="name"
                            item-value="value"
                            variant="outlined"
                            hide-details
                            density="compact"
                            menu-icon="fa fa-angle-down"
                            clear-icon="fa fa-close"
                            :disabled="loadingFilter"
                            @update:modelValue="setFilter"
                        ></v-select>
                    </v-col>
                </v-row>
            </v-col>
            

            <v-col 
                sm="12"
                md="4"
            >
                <v-row
                    class="justify-end align-center"
                    no-gutters
                >
                    <v-col 
                        sm="12"
                        md="2"
                    >
                        <v-select
                            v-model="limitSet"
                            label="На стр."
                            :items="limitItems"
                            variant="outlined"
                            hide-details
                            density="compact"
                            menu-icon="fa fa-angle-down"
                            :disabled="loadingFilter"
                            @update:modelValue="setFilter"
                        ></v-select>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>

    <v-skeleton-loader
        :loading="preLoading"
        type="table-row@5"
        class="w-100"
    >
        <div class="app-device">
        
            <div class="app-device__list title">
                <div>#</div>
                <div>ID устройства</div>
                <div>Название</div>
                <div>Сеть/Wi-Fi</div>
                <div>Прошивка</div>
                <div>Статус</div>
                <div>Действия</div>
            </div>

            <div
                class="app-device__list"
                v-for="(item, key) in devices"
                :key="key"
            >
                <div class="col">
                    {{key + 1}}
                </div>
                <div class="col">{{ item.device_id }}</div>
                <div class="col">{{ item.name }}</div>
                <div class="col" v-text="item.network_connect ? item.network_connect + ' (ip: ' + item.network_local_ip + ')' : 'Нет подключения'"></div>
                <div class="col">{{ item.firmware_device }} {{ item.firmware_version_name }} (v.{{ item.firmware_version }})</div>
                <div class="col" v-html="item.statusText"></div>
                <div class="col">
                    <v-btn
                        :to="{ name: 'device_stats', params: { id: item.id }}"
                        @click="setTitle(item.name)"
                        size="small"
                        icon
                        variant="plain"
                    >
                        <v-icon size="14">fas fa-chart-simple</v-icon>
                    </v-btn>

                    <v-btn
                        @click="updateWiFi(item)"
                        size="small"
                        icon
                        variant="plain"
                        :disabled="!item.status_device"
                    >
                        <v-icon size="14">fas fa-wifi</v-icon>
                    </v-btn>

                    <v-btn
                        @click="addOrEdit(item, key)"
                        size="small"
                        icon
                        variant="plain"
                    >
                        <v-icon size="14">fa fa-cog</v-icon>
                    </v-btn>
                </div>

                <div
                    v-if="loadingItem[key]"
                    class="loading"
                >
                    <v-progress-circular
                        color="indigo-darken-3"
                        indeterminate
                    ></v-progress-circular>
                </div>

            </div>

            <div v-if="!devices.length" class="d-flex text-center w-100 pa-2 justify-center align-center">
                Нет данных
            </div>
        

            <div v-if="pageTotal > 1" class="text-center pt-1 pb-1">
                <v-pagination
                    v-model="page"
                    :length="pageTotal"
                    :total-visible="7"
                    density="compact"
                    prev-icon="fas fa-chevron-left"
                    next-icon="fas fa-chevron-right"
                ></v-pagination>
            </div>
        </div>
    </v-skeleton-loader>

    <EditDevicePopup
        :open="openEdit"
        :index="indexEdit"
        :item="itemEdit"
        @close="closeAddOrEdit"
    />

    <UpdateWifiPopup
        :device_id="openEditWifi"
        @close="closeEditWifi"
    />

</template>
<script>

import EditDevicePopup from '@/components/device/EditDevicePopup'
import UpdateWifiPopup from '@/components/device/UpdateWifiPopup'

export default {
    name: 'DeviceListPage',
    components: {
        EditDevicePopup,
        UpdateWifiPopup
    },
    data: () => ({
        page: 1,
        title: '',
        loading: false,
        items: [],
        limitItems: [10, 20, 50, 100],
        offset: 0,
        limitSet: 20,
        total: 0,
        searchTypeItems: [
            {
                'value': 'id',
                'name': 'ID'
            },
            {
                'value': 'device_id',
                'name': 'ID устройства'
            },
            {
                'value': 'name',
                'name': 'Название'
            }
        ],
        searchStatusItems: [
            {
                'value': 0,
                'name': 'Оффлайн'
            },
            {
                'value': 1,
                'name': 'Онлайн'
            }
        ],
        loadingFilter: false,
        filter: {
            searchType: 'device_id',
            search: '',
            status: null
        },
        timerSearch: null,

        loadingItem: {},

        itemEdit: null,
        indexEdit: null,
        openEdit: false,

        firmwares: [],

        timeGet: null,

        openEditWifi: null
    }),
    mounted () {
    },
    watch: {
        page() {
            
            this.offset = ((this.limit * this.page) - this.limit)

            this.$store.commit('Update', {
                key: 'preloader',
                value: true
            })

            this.get()
        }
    },
    methods: {
        init() {
            this.items = []
            this.offset = 0

            this.get()
        },
        get(loading = true) {

            this.loading = loading

            clearTimeout(this.timeGet)

            let filter = {}

            if(this.filter.search) {
                filter.search = this.filter.search
                filter.type = this.filter.searchType
            }

            if(this.filter.status !== null) {
                filter.status = this.filter.status
            }

            this.$store.dispatch('API', {
                method: 'GET',
                url: '/device',
                params: {
                    'limit': this.limit,
                    'offset': this.offset,
                    'filter': filter
                }
            }).then(res => {

                if(res.data){
                    if(res.data.status === 'success') {

                        this.total = res.data.data.total

                        this.items = res.data.data.items

                        this.firmwares = res.data.data.firmwares

                        this.timerGet()

                    }
                }

                if(loading) {
                    this.loading = false
                    this.loadingFilter = false

                    this.$store.commit('Update', {
                        key: 'preloader',
                        value: false
                    })
                }
            })
        },
        timerGet() {
            clearTimeout(this.timeGet)

            this.timeGet = setTimeout(() => {
                this.get(false)
            }, 10000)
        },
        setFilter(input=false) {

            this.$store.commit('Update', {
                key: 'itemsLimit',
                value: this.limitSet
            })

            clearTimeout(this.timerSearch)

            this.$store.commit('Update', {
                key: 'preloader',
                value: true
            })

            if(input) {

                this.timerSearch = setTimeout(() => {
                    this.loadingFilter = true

                    this.init()

                    this.timerSearch = null
                }, 500)

            }else{
                this.init()
                this.loadingFilter = false
            }
        },
        addOrEdit(item = null, index = null) {
            this.itemEdit = item
            this.indexEdit = index

            this.openEdit = true
        },
        closeAddOrEdit(data) {
            this.itemEdit = null
            this.indexEdit = null

            this.openEdit = false

            if(data.index !== null) {
                if(data.item) this.items[data.index] = data.item
            }else{
                if(data.item) {
                    let newItems = [data.item]

                    this.items = newItems.concat(this.items)
                }
            }
        },
        setTitle(title = '') {
            if(title) {
                this.$store.commit('Update', {
                    key: 'titlePage',
                    value: title
                })

                this.title = title
            }
        },
        updateWiFi(item) {
            this.openEditWifi = item.id
        },
        closeEditWifi() {
            this.openEditWifi = null
        },
    },
    computed: {
        titleHeading() {
            return this.title || this.$route.meta.title
        },
        devices() {

            let items = []

            this.items.forEach(item => {

                if(Number(item.status_device)) item.statusText = '<span class="text-success">В сети</span>'
                else item.statusText = '<span class="text-error">Оффлайн</span>'

                item.created = this.$moment(item.created_at).calendar(null, {
                    sameDay: '[Сегодня], HH:mm:ss',
                    nextDay: '[Завтра], HH:mm:ss',
                    nextWeek: 'DD.MM.YYYY HH:mm:ss',
                    lastDay: 'DD.MM.YYYY HH:mm:ss',
                    lastWeek: 'DD.MM.YYYY HH:mm:ss',
                    sameElse: 'DD.MM.YYYY HH:mm:ss'
                })

                item.updated = this.$moment(item.updated_at).calendar(null, {
                    sameDay: '[Сегодня], HH:mm:ss',
                    nextDay: '[Завтра], HH:mm:ss',
                    nextWeek: 'DD.MM.YYYY HH:mm:ss',
                    lastDay: 'DD.MM.YYYY HH:mm:ss',
                    lastWeek: 'DD.MM.YYYY HH:mm:ss',
                    sameElse: 'DD.MM.YYYY HH:mm:ss'
                })

                items.push(item)
            })

            return items
        },
        preLoading() {
            return this.$store.state.preloader
        },
        preloaderGlobal() {
            return this.$store.state.preloaderGlobal
        },
        pageTotal() {

            if(!this.total) return 0

            return Math.ceil(this.total/this.limit)
        },
        limit() {
            return this.$store.state.itemsLimit
        }
    },
    created() {

        this.limitSet = this.limit

        this.$store.commit('Update', {
            key: 'preloader',
            value: true
        })
        this.init()        
    },
    unmounted() {
        clearTimeout(this.timeGet)
    }
}
</script>