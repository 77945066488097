<template>
    <div class="app-catalog">
        <h1 class="text-h4 mb-1">{{ titleHeading }}</h1>

        <div class="app-catalog__filter">
            <v-row 
                no-gutters
            >
                <v-col 
                    sm="12"
                    md="8"
                >
                    <v-row
                        class="justify-space-between align-center"
                        no-gutters
                    >
                        <v-col 
                            sm="8"
                            md="8"
                            class="pr-2"
                        >
                            <v-text-field
                                v-model="filter.search"
                                clearable
                                label="Поиск"
                                prepend-icon="fa fa-search"
                                variant="outlined"
                                density="compact"
                                hide-details
                                clear-icon="fa fa-close"
                                :loading="loadingFilter ? 'indigo-darken-3' : false"
                                @keyup="setFilter(true)"
                                @click:clear="setFilter"
                            ></v-text-field>
                        </v-col>
                        <v-col 
                            sm="4"
                            md="4"
                        >
                            <v-select
                                v-model="filter.type"
                                clearable
                                label="Тип"
                                :items="searchTypeItems"
                                item-title="name"
                                item-value="value"
                                variant="outlined"
                                hide-details
                                density="compact"
                                menu-icon="fa fa-angle-down"
                                clear-icon="fa fa-close"
                                :disabled="loadingFilter"
                                @update:modelValue="setFilter"
                            ></v-select>
                        </v-col>
                    </v-row>
                </v-col>
                

                <v-col 
                    sm="12"
                    md="4"
                >
                    <v-row
                        class="justify-end align-center"
                        no-gutters
                    >
                        <v-col 
                            sm="12"
                            md="2"
                        >
                            <v-select
                                v-model="limitSet"
                                label="На стр."
                                :items="limitItems"
                                variant="outlined"
                                hide-details
                                density="compact"
                                menu-icon="fa fa-angle-down"
                                :disabled="loadingFilter"
                                @update:modelValue="setFilter"
                            ></v-select>
                        </v-col>
                        <v-col 
                            sm="12"
                            md="8"
                            class="text-sm-center text-md-right"
                        >
                            <v-btn
                                color="success"
                                :disabled="!sendPushStatus"
                                @click="openDialog"
                            >
                                Отправить уведомление
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>

        <v-skeleton-loader
            :loading="preLoading"
            type="table-row@5"
            class="w-100"
        >
            <div class="app-notification">
            
                <div class="app-notification__list title">
                    <div>#</div>
                    <div>Тема</div>
                    <div>Сообщение</div>
                    <div>Тип</div>
                    <div>Отправитель</div>
                    <div>Отправленно</div>
                    <!-- <div>Действия</div> -->
                </div>

                <div
                    class="app-notification__list"
                    v-for="(item, key) in getItems"
                    :key="key"
                >
                    <div class="col">
                        {{key + 1}}
                    </div>
                    <div class="col">{{ item.title }}</div>
                    <div class="col">{{ item.message }}</div>
                    <div class="col">{{ item.type }}</div>
                    <div class="col">{{ item.author }}</div>
                    <div class="col">{{ item.created }}</div>
                    <!-- <div class="col">
                        <v-btn

                            size="small"
                            icon
                            variant="plain"
                        >
                            <v-icon size="14">fas fa-chart-simple</v-icon>
                        </v-btn>

                        <v-btn

                            size="small"
                            icon
                            variant="plain"
                        >
                            <v-icon size="14">fa fa-cog</v-icon>
                        </v-btn>
                    </div> -->
                </div>

                <div v-if="!getItems.length" class="d-flex text-center w-100 pa-2 justify-center align-center">
                    Нет данных
                </div>
            

                <div v-if="pageTotal > 1" class="text-center pt-1 pb-1">
                    <v-pagination
                        v-model="page"
                        :length="pageTotal"
                        :total-visible="7"
                        density="compact"
                        prev-icon="fas fa-chevron-left"
                        next-icon="fas fa-chevron-right"
                    ></v-pagination>
                </div>
            </div>
        </v-skeleton-loader>
    </div>

    <NewPush
        :open="openDialogPush"
        @close="closeDialogPush"
    />
</template>
<script>
import NewPush from '@/components/popus/NewPush';

export default {
    name: 'NotificationListPage',
    components: {
        NewPush
    },
    data: () => ({
        page: 1,
        title: '',
        loading: false,
        items: [],
        limitItems: [10, 20, 50, 100],
        offset: 0,
        limitSet: 20,
        total: 0,
        searchTypeItems: [
            {
                'value': 0,
                'name': 'Системные'
            },
            {
                'value': 1,
                'name': 'Менеджер'
            },
            {
                'value': 2,
                'name': 'Администратор'
            },
            {
                'value': 3,
                'name': 'Устройства'
            }
        ],
        loadingFilter: false,
        filter: {
            search: '',
            type: null
        },
        sendPushStatus: false,
        openDialogPush: false
    }),
    mounted () {
    },
    watch: {
        page() {
            
            this.offset = ((this.limit * this.page) - this.limit)

            this.$store.commit('Update', {
                key: 'preloader',
                value: true
            })

            this.get()
        }
    },
    methods: {
        init() {
            this.items = []
            this.offset = 0

            this.get()
        },
        get(loading = true) {

            this.loading = loading

            let filter = {}

            if(this.filter.search) {
                filter.search = this.filter.search
            }

            if(this.filter.type === 0 || this.filter.type > 0) {
                filter.type = this.filter.type
            }

            this.$store.dispatch('API', {
                method: 'GET',
                url: '/notification',
                params: {
                    'limit': this.limit,
                    'offset': this.offset,
                    'filter': filter
                }
            }).then(res => {

                if(res.data){
                    if(res.data.status === 'success') {

                        this.total = res.data.data.total

                        this.items = res.data.data.items

                        this.sendPushStatus = res.data.data.new_push

                    }
                }

                if(loading) {
                    this.loading = false
                    this.loadingFilter = false

                    this.$store.commit('Update', {
                        key: 'preloader',
                        value: false
                    })
                }
            })
        },
        setFilter(input=false) {

            this.$store.commit('Update', {
                key: 'itemsLimit',
                value: this.limitSet
            })

            clearTimeout(this.timerSearch)

            this.$store.commit('Update', {
                key: 'preloader',
                value: true
            })

            if(input) {

                this.timerSearch = setTimeout(() => {
                    this.loadingFilter = true

                    this.init()

                    this.timerSearch = null
                }, 500)

            }else{
                this.init()
                this.loadingFilter = false
            }
        },
        setTitle(title = '') {
            if(title) {
                this.$store.commit('Update', {
                    key: 'titlePage',
                    value: title
                })

                this.title = title
            }
        },
        openDialog() {
            this.openDialogPush = true
        },
        closeDialogPush(data) {
            if(data.save) {
                this.init()
            }

            this.openDialogPush = false
        }
    },
    computed: {
        titleHeading() {
            return this.title || this.$route.meta.title
        },
        getItems() {

            let items = []

            this.items.forEach(item => {

                item.created = this.$moment(item.created_at).calendar(null, {
                    sameDay: '[Сегодня], HH:mm:ss',
                    nextDay: '[Завтра], HH:mm:ss',
                    nextWeek: 'DD.MM.YYYY HH:mm:ss',
                    lastDay: 'DD.MM.YYYY HH:mm:ss',
                    lastWeek: 'DD.MM.YYYY HH:mm:ss',
                    sameElse: 'DD.MM.YYYY HH:mm:ss'
                })

                items.push(item)
            })

            return items
        },
        preLoading() {
            return this.$store.state.preloader
        },
        preloaderGlobal() {
            return this.$store.state.preloaderGlobal
        },
        pageTotal() {

            if(!this.total) return 0

            return Math.ceil(this.total/this.limit)
        },
        limit() {
            return this.$store.state.itemsLimit
        }
    },
    created() {

        this.limitSet = this.limit

        this.$store.commit('Update', {
            key: 'preloader',
            value: true
        })
        this.init()        
    },
    unmounted() {
        clearTimeout(this.timeGet)
    }
}
</script>