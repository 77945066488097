<template>
    <v-dialog
        v-model="openDialog"
        persistent
        width="600px"
    >
        <v-card>
            <v-card-title class="text-h5">
                {{ titleDialog }}
            </v-card-title>
            <v-card-text>
                <Form
                    @submit="submit"
                    ref="pushForm"
                    :validation-schema="schema"
                >
                    <Field
                        v-model="title"
                        name="title"
                        v-slot="{ field, errors }"  
                    >
                        <v-text-field
                            v-bind="field"
                            :model-value="field.value"
                            label="Заголовок"
                            color="indigo-darken-3"
                            variant="underlined"
                            :error-messages="errors"
                            required
                            :disabled="loading"
                        />
                    </Field>

                    <div class="mt-3 mb-3">
                        <Field
                            v-model="message"
                            name="message"
                            v-slot="{ field, errors }"
                        >
                            <v-textarea
                                v-bind="field"
                                :model-value="field.value"
                                label="Сообщение"
                                auto-grow
                                color="indigo-darken-3"
                                variant="underlined"
                                rows="1"
                                row-height="15"
                                :error-messages="errors"
                                :disabled="loading"
                            ></v-textarea>
                            
                        </Field>
                    </div>


                </Form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    variant="text"
                    @click="close()"
                >
                    Отмена
                </v-btn>
                <v-btn
                    color="green-darken-1"
                    variant="text"
                    :loading="loading"
                    @click="submit"
                >
                    {{ buttonText }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { Form, Field } from 'vee-validate'
import ru from "yup-locale-ru"
import * as yup from 'yup'

yup.setLocale(ru)

export default {
    props: {
        open: {
            type: Boolean,
            default: false
        }
    },
    emits: ['close'],
    components: {
        Form,
        Field
    },
    name: 'NewPush',
    data: () => ({
        title: null,
        message: null,
        openDialog: false,
        loading: false
    }),
    watch: {
        open(value) {

            this.openDialog = value

            this.loading = false

            if(value) {
                this.title = null
                this.message = null

            }else{
                this.$refs.pushForm.resetForm()
            }
        }
    },
    methods: {
        close(save = false) {
            this.$emit('close', {
                save: save
            })
        },
        submit() {

            this.$refs.pushForm.validate().then(data => {
                if(data.valid) {
                    this.create()
                }
            })

            
        },
        create() {
            this.loading = true

            this.$store.dispatch('API', {
                method: 'POST',
                url: '/notification/send',
                data: {
                    title: this.title,
                    message: this.message
                }
            }).then(res => {
                if(res.data) {
                    if(res.data.status === 'success') {
                        this.close(true)
                    }
                } else if(res.response && res.response.status == 422) {
                    if(res.response.data.errors) {
                        this.$refs.pushForm.setErrors(res.response.data.errors)
                    }
                }

                this.loading = false
            })
        },
    },
    computed: {
        titleDialog() {
            return 'Отправить PUSH'
        },
        buttonText() {
            return 'Отправить'
        },
        schema() {
            return yup.object({
                title: yup.string().required().min(2).max(255),
                message: yup.string().required()
            })
        }
    }
}
</script>