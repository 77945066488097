<template>
    <p align="center" class="text-h5 mb-2">Авторизация</p>

    <v-row v-if="loading" class="mt-4 mb-4">
        <v-col cols="12" align-self="center" style="text-align: center;">
            <v-progress-circular
                indeterminate
                size="40"
            >
            </v-progress-circular>
        </v-col>
    </v-row>

    <v-alert v-if="(!uuid || !access) && !loading"
        density="compact"
        type="warning"
        title="Доступ запрещен"
        text="Отказано в доступе!"
        border

    >
        <template v-slot:prepend>
            <v-icon size="26" icon="fa fa-exclamation"></v-icon>
        </template>
    </v-alert>
</template>

<script>

export default {
    name: 'SignIn',
    components: {},
    data: () => ({
        loading: false,
        access: false
    }),
    mounted() {
        this.init()
    },
    methods: {
        init() {
            this.loading = true
            this.access = false

            if(this.uuid) {
                this.$store.dispatch('API', {
                    method: 'POST',
                    url: '/auth',
                    data: {
                        token: this.uuid
                    }
                }).then(res => {

                    if(res.data && res.data.status === 'success'){

                        this.access = true

                        this.$store.commit('Update', {
                            key: 'token',
                            value: res.data.data.token
                        })

                        this.$store.commit('Update', {
                            key: 'user',
                            value: res.data.data.user
                        })

                        this.$router.push({ name: 'main' })
                    }

                    this.loading = false
                })
            }else{
                this.loading = false
            }
        }
    },
    computed: {
        uuid () {
            return this.$route.params.uuid
        }
    }
}
</script>
