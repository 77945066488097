import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'

import MainLayout from '@/layouts/MainLayout.vue'
import AuthLayout from '@/layouts/AuthLayout.vue'

import SignIn from '@/pages/auth/SignIn.vue'

import Dashboard from '@/pages/Dashboard.vue'

import DevicePage from '@/pages/device/DevicePage.vue'
import DeviceListPage from '@/pages/device/DeviceListPage.vue'
// import DeviceViewPage from '@/pages/device/DeviceViewPage.vue'
import DeviceStatsPage from '@/pages/device/DeviceStatsPage.vue'

import NotificationListPage from '@/pages/NotificationListPage.vue'

const routes = [
    {
        path: '/',
        name: 'main',
        component: MainLayout,
        meta: {
            requiresAuth: true,
            title: 'Главная',
        },
        children: [
            {
                path: '',
                name: 'home',
                component: Dashboard,
            },
            {
                path: 'dashboard',
                name: 'dashboard',
                redirect: '/',
            },
            {
                path: 'device',
                name: 'device',
                component: DevicePage,
                meta: {
                    title: 'SmartBox',
                },
                children: [
                    {
                        path: '',
                        name: 'device_page',
                        component: DeviceListPage,
                    },
                    {
                        path: ':id/stats',
                        name: 'device_stats',
                        component: DeviceStatsPage,
                        meta: {
                            title: 'Статистика',
                        }
                    }
                ]
            },
            {
                path: 'notification',
                name: 'notification',
                component: NotificationListPage,
                meta: {
                    title: 'Уведомления'
                }
            },
        ]
    },
    {
        path: '/auth',
        name: 'auth',
        component: AuthLayout,
        meta: {
            requiresAuth: false,
            title: 'Авторизация',
        },
        redirect: '/auth/',
        children: [
            {
                path: ':uuid?',
                name: 'sign_in',
                component: SignIn,
                meta: {
                    title: 'Авторизация',
                }
            },
        ]
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {

    const currentUser = store.getters.isLoggedIn
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

    if (requiresAuth && !currentUser) {
        next({ name: 'auth' })
    } else if (!requiresAuth && currentUser) {
        // if(to.name !== 'permission')
        //     next({ name: 'permission' })
        // else
        //     next()
        next({ name: 'main' })
    } else {
        next()
    }
})

export default router
