<template>
    <v-navigation-drawer
        absolute
        left
        elevation="6"
    >
        <v-list
            density="compact"
            nav
        >
            <v-list-item to="/dashboard">
                <template v-slot:prepend>
                    <v-icon size="20">fa fa-house</v-icon>
                </template>
                <template v-slot:title>
                    <span class="font-weight-bold">Главная</span>
                </template>
            </v-list-item>

            <v-list-item to="/device">
                <template v-slot:prepend>
                    <v-icon size="20">fa fa-microchip</v-icon>
                </template>
                <template v-slot:title>
                    <span class="font-weight-bold">SmartBox</span>
                </template>
            </v-list-item>

            <v-divider class="mt-3 mb-3"></v-divider>

            <v-list-item to="/notification">
                <template v-slot:prepend>
                    <v-icon size="20">fa fa-bell</v-icon>
                </template>
                <template v-slot:title>
                    <span class="font-weight-bold">Уведомления</span>
                </template>
            </v-list-item>

        </v-list>
    </v-navigation-drawer>
</template>
<script>
export default {
    name: 'NavigationPanel'
}
</script>